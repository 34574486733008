var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('section',{staticClass:"iconHeader primary white--text"},[_c('v-container',{staticClass:"pa-2 px-5",attrs:{"fluid":true}},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center pa-0",attrs:{"cols":"6","sm":"6","md":"6","lg":"6"}},[_c('v-btn',{staticClass:"d-flex d-md-none",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.openApps($event)}}},[_c('div',{staticClass:"d-flex align-end"},[_c('v-icon',[_vm._v("menu")])],1)]),_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_c('img',{staticClass:"logo mx-3",attrs:{"src":require("@/assets/whitelogo.png"),"alt":"logo","height":_vm.height},on:{"click":_vm.goHome}})])],1),_c('v-col',{staticClass:"d-flex justify-end align-center pa-0",attrs:{"cols":"6","sm":"6","md":"6","lg":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"langBtn mx-3",attrs:{"icon":""},on:{"click":_vm.changeLang}},on),[_c('div',{staticClass:"d-flex align-end darkgray--text"},[_c('span',{staticClass:"font-xsmall"},[_vm._v(_vm._s(_vm.$t("langShortCut")))]),_c('v-icon',[_vm._v("fas fa-globe")])],1)])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("changeLng")))])]),_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('div',_vm._g(_vm._b({staticClass:"mx-3 d-flex align-center",attrs:{"icon":""}},'div',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("fas fa-graduation-cap")]),_c('v-icon',[_vm._v("arrow_drop_down")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("CHANGE_STAGE")))])])]}}])},[_c('v-list',{staticClass:"pa-0"},[(_vm.userStages && _vm.userStages.length)?_c('v-list-item-group',_vm._l((_vm.userStages),function(userStage){return _c('v-list-item',{key:userStage.stageId,class:userStage.stageId === _vm.$store.state.app.activeStageId ? 'highlighted' : '',on:{"click":function($event){return _vm.changeSelectedStage(userStage.stageId)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"selectedStage"},[_c('span',{staticClass:"breakTitle"},[_vm._v(" "+_vm._s(_vm._f("servertranslate")(userStage.stageName))+" ")])])],1)],1)}),1):_c('v-list-item-group',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"selectedStage"},[_c('span',{staticClass:"breakTitle warning--text"},[_vm._v(" "+_vm._s(_vm.$t('NO_STAGE'))+" ")])])],1)],1)],1)],1)],1),(_vm.isUserTechnicalSupport)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.goToImportStaffCourses}},on),[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("sync")])],1)],1)]}}],null,false,520044416)},[_c('span',[_vm._v(_vm._s(_vm.$t("SYNC_STAFF_COURSES")))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("email")])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("email")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("notifications")])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("notifications")))])]),_c('v-menu',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"mx-3 d-flex align-center",attrs:{"icon":""}},'div',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-user-alt")]),_c('v-icon',[_vm._v("arrow_drop_down")])],1)]}}])},[_c('v-list',{staticClass:"pa-0"},[_c('v-list-item-group',[_c('v-list-item',{on:{"click":_vm.goToProfile}},[(_vm.$store.state.auth.userImage)?_c('img',{staticClass:"userImage rounded-circle mx-2",attrs:{"src":'data:image/png;base64,' + _vm.$store.state.auth.userImage,"alt":_vm.$store.state.auth.username}}):_vm._e(),_vm._v(" "+_vm._s(this.$store.state.auth.username)+" ")]),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.logout}},[_c('p',{staticClass:"mb-0 mx-2",attrs:{"color":"success","id":"header-btn-logout"}},[_vm._v(" "+_vm._s(_vm.$t("Logout"))+" ")])])],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }