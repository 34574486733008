/* eslint-disable @typescript-eslint/no-explicit-any */

import { Lang, ProfileData } from '@/types/general.type';
import { HomeDateResult } from '@/types/home.types';
import provider from './main.provider';

export default class HomeProvider {
  async getSubSystems(activeStageId?: string): Promise<HomeDateResult> {
    const result: any = await provider.get('/api/user/home', { params: { activeStageId } });

    return result;
  }

  async getProfileData(userName: string): Promise<ProfileData> {
    const result: any = await provider.get(`/api/user/${userName}`);
    return result;
  }

  async changePassword(userName: string, currentPassword: string,
    newPassword: string): Promise<Lang> {
    const result: any = await provider.put(`/api/user/restPass/${userName}`,
      { currentPassword, newPassword });
    return result;
  }

  async updateProfileData(userName: string, data: any): Promise<Lang> {
    const result: any = await provider.put(`/api/user/${userName}`, data);
    return result;
  }
}
