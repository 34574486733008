import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class Language extends Vue {
  switchLang() {
    this.$store.commit('switchLang');
    const newLang = this.$store.state.lang.lang;
    if (newLang === 'en') {
      this.$vuetify.rtl = false;
      this.$i18n.locale = 'en';
    } else {
      this.$vuetify.rtl = true;
      this.$i18n.locale = 'ar';
    }
  }
}
