/* eslint-disable @typescript-eslint/no-explicit-any */
import { Scope } from '@/types/general.type';
import provider from './main.provider';
import store from '../store/index';

export default class ScopeProvider {
  static async getScopes(parentId?: string,
    rightId?: string): Promise<Scope[]> {
    const appState: any = store.state;
    const { activeApp } = appState.app;
    if (!activeApp) {
      return [];
    }
    const result: { scopes: Scope[] } = await provider.get(
      `/api/user/scopes/${activeApp}?foundation=${parentId || ''}&right=${rightId || ''}`,
    );
    const scopList: Scope[] = result.scopes.map((item) => {
      const mappedItem: Scope = {
        id: item.foundRealID,
        name: item.foundName,
        childCounts: item.childrenCount,
      };
      if (item.childrenCount) mappedItem.children = [];
      return mappedItem;
    });
    return scopList;
  }
}
