







































import { Component, Vue } from 'vue-property-decorator';
import HeaderComponent from '../components/main/header.component.vue';
import FooterComponent from '../components/main/footer.component.vue';
import SideMenuComponent from '../components/sideMenu.component.vue';

@Component({
  components: { HeaderComponent, FooterComponent, SideMenuComponent },
})
export default class MainLayout extends Vue {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.token) {
        vm.$router.push('/login');
      }
    });
  }

  mounted() {
    this.$vuetify.goTo(0);
  }

  get appDrawerOpen() {
    return !this.$store.state.app.mini;
  }
}
