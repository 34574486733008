











































































import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { SubSystemsRes, HomeDateResult } from '@/types/home.types';
import HomeProvider from '../providers/home.provider';
import ActiveApp from '../mixins/activeApp.mixin';
import Toaster from '../mixins/toaster.mixin';
import { Lang } from '../types/general.type';
import { QMANS_MAIN_APPS_IDS } from '../enums';

@Component
export default class Applications extends mixins(ActiveApp, Toaster) {
  homeProvider = new HomeProvider();

  applications?: SubSystemsRes[] = [];

  mounted() {
    this.getApplications();
  }

  async getApplications() {
    const { activeStageId } = this.$store.state.app;
    try {
      const result: HomeDateResult = await this.homeProvider.getSubSystems(activeStageId);
      this.applications = result.subsystems?.filter(
      (x) => QMANS_MAIN_APPS_IDS
        .includes(
          x.subSysId,
        )
      );

      this.$store.commit('updateUserImage', {
        userImage: result.profileData?.userImg,
      });
      this.$store.commit('setCanSyncCourses', result.profileData?.canSyncCourses);
      this.$store.commit('setUserStagesData', result.userStages);
      this.$store.commit('setActiveStageId', result.currentStageId);
      this.$store.commit('setTechnicalSupport', result.isTechnicalSupport);
    } catch (err) {
      this.$store.commit('setActiveStageId', '');
      this.$store.commit('setTechnicalSupport', null);
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  openApp(appId: string) {
    this.getCurrentApp(appId);
  }
}
