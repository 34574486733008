













































































































































































import { StageData } from '@/types/home.types';
import Component, { mixins } from 'vue-class-component';
import Language from '../../mixins/language.mixin';

@Component
export default class Header extends mixins(Language) {
  goToDashboard() {
    this.$router.push('/dashboard').catch();
  }

  logout() {
    this.$store.commit('logout');
    this.$router.push('/login');
  }

  goToProfile() {
    const { username } = this.$store.state.auth;
    this.$router.push({
      name: 'profile',
      params: {
        username,
      },
    });
  }

  get height() {
    switch (this.$vuetify.breakpoint.name) {
      default:
        return 30;
      case 'xs':
        return 30;
      case 'sm':
        return 35;
      case 'md':
        return 35;
      case 'lg':
        return 35;
      case 'xl':
        return 35;
    }
  }

  get userStages() {
    if (this.$store.state.app && Array.isArray(this.$store.state.app.userStagesData)) {
      return (this.$store.state.app.userStagesData) as StageData[];
    }
    return [];
  }

  get isUserTechnicalSupport() {
    return this.$store.state.app && this.$store.state.app.isTechnicalSupport;
  }

  changeLang() {
    this.switchLang();
  }

  goHome() {
    this.resetLocalStore();
    this.$router.push('/');
  }

  openApps() {
    this.$store.commit('openApps');
    this.$store.commit('openDrawer');
  }

  changeSelectedStage(stageId: string) {
    if (stageId && stageId !== this.$store.state.app.activeStageId) {
      this.resetLocalStore();
      this.$store.commit('setActiveStageId', stageId);
      this.$router.push('/dashboard').then(() => this.$router.go(0));
    }
  }

  resetLocalStore() {
    this.$store.commit('resetCourse');
    this.$store.commit('resetAllFilters');
    this.$store.commit('clearNavQuestionList');
    this.$store.commit('resetScope');
    this.$store.commit('selectApp', {
      appId: '',
      activeChildRight: '',
      subSystemId: '',
    });
    this.$store.commit('openApps');
  }

  goToImportStaffCourses() {
    this.$router.push({
      name: 'updateStaffCourses',
    });
  }
}
