






































/* eslint-disable */

import { Component } from "vue-property-decorator";
import ScopeProvider from "../providers/scope.provider";
import { Scope } from "@/types/general.type";
import emitter from "@/shared/utils/emitter.ts";
import { mixins } from "vue-class-component";
import Toaster from "../mixins/toaster.mixin";
import { Lang } from "../types/general.type";

@Component
export default class ScopeTree extends mixins(Toaster) {
  treeData: Array<Scope> = [];

  active = [];

  tree = [];

  open: Array<Scope> = [];
  focused = false;
  elevation = 0;

  mounted() {
    emitter.on("scopFocus", () => {
      this.setFocus();
    });
  }
  setFocus() {
    this.focused = true;
    this.elevation = 3;
  }
  removeFocus() {
    this.focused = false;
    this.elevation = 0;
  }

  itemSelected(currentSelected: Array<Scope>) {
    const [selectedScope] = currentSelected;
    const ids = selectedScope?.id?.split(".");
    let currentParent: Array<Scope> | undefined = this.treeData;
    let currentId = "";
    let scopeList: Array<Scope> = [];
    ids?.forEach((id) => {
      if (id) {
        currentId += id + ".";
        let item: Scope | undefined = currentParent?.find(
          (parent) => parent.id === currentId
        );
        if (item) {
          currentParent = item.children;
          scopeList.push(item);
        }
      }
    });
    this.changeScope(selectedScope, scopeList);
  }

  clickable(item) {
    const scopeLevels = item.id.split(".");
    if(this.isDepartmentLevelMode){
      return scopeLevels.length >= 4;
    }
    return !(this.isLastLevelMode && item.childCounts);

  }
  get isLastLevelMode() {
    const scopeState: any = this.$store.state;
    const { isLastLevel } = scopeState.scope;
    return isLastLevel;
  }

  get isDepartmentLevelMode() {
    const scopeState: any = this.$store.state;
    const { isDepartmentLevel } = scopeState.scope;
    return isDepartmentLevel;
  }

  facultyLevelCheck(parentScope) {
    if (!parentScope.id) {
      return false;
    }
    const scopeState: any = this.$store.state;
    const { facultyLevel } = scopeState.scope;
    const parentScopelen = parentScope.id.split(".").length > 4;
    return facultyLevel && parentScopelen;
  }

  async getScope(parentScope?: Scope) {
    try {
      const nodes = await ScopeProvider.getScopes(
        parentScope ? parentScope.id : "",
        this.$store.state.app.activeChildRight
      );

      if (!this.treeData.length) {
        this.treeData = nodes;
      } else if (parentScope) {
        const parentNode = this.findById(this.treeData, parentScope.id);
        parentNode.children = [...nodes];
      }
    } catch (err) {
      this.errorToaster(err as Lang, { duration: 5000 });
    }
  }

  findById(tree, parentID) {
    const ids = parentID.split(".");

    let currentTree: Array<Scope> | undefined = tree;
    let currentId = "";
    let currentItem!: Scope;
    ids.forEach((id) => {
      if (id) {
        currentId += id + ".";
        let item: Scope | undefined = currentTree?.find(
          (parent) => parent.id === currentId
        );
        if (item) {
          currentTree = item.children;
          currentItem = item;
        }
      }
    });
    return currentItem;
  }

  changeScope(scope: Scope, list: Scope[]) {
    if (!scope) {
      return;
    }
    this.removeFocus();
    const scopeLevels = scope.id?.split('.');
    if ((this.isLastLevelMode && scope.childCounts) || (this.isDepartmentLevelMode && scopeLevels!.length < 4)) {
      this.open = [...this.open, scope];
      return;
    }
    this.$store.commit("scopeList", list);
    this.$store.commit("selectScope", {
      scope,
    });

    this.$store.commit("openApps");
    if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) {
      this.$store.commit("closeDrawer");
    }
  }
}
