import { render, staticRenderFns } from "./scopeTree.component.vue?vue&type=template&id=32c8bc1e&scoped=true&"
import script from "./scopeTree.component.vue?vue&type=script&lang=ts&"
export * from "./scopeTree.component.vue?vue&type=script&lang=ts&"
import style0 from "./scopeTree.component.vue?vue&type=style&index=0&id=32c8bc1e&lang=scss&scoped=true&"
import style1 from "./scopeTree.component.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./scopeTree.component.vue?vue&type=style&index=2&id=32c8bc1e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32c8bc1e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCard,VTreeview})
