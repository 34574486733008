

































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from '@/types/router.type';
import ScopeTreeComponent from './scopeTree.component.vue';
import ApplicationsComponent from './applications.component.vue';

@Component({
  components: { ScopeTreeComponent, ApplicationsComponent },
})
export default class SideMenu extends Vue {
  drawer = !(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs);

  rightMenu = this.$store.state.lang.lang === 'ar';

  $refs!: {
    scope: ScopeTreeComponent;
  };

  @Watch('$route', { deep: true })
  onRouteChanged(newRoute: Route, oldRoute: Route) {
    if (newRoute.name !== oldRoute.name) {
      this.getScopes();
    }
  }

  mounted() {
    if (this.$store.state.app.activeApp) {
      this.getScopes();
    }
  }

  getScopes() {
    this.$refs.scope.treeData = [];
    this.$refs.scope.getScope();
  }

  @Watch('$store.state.drawerState', { deep: true })
  onStoreDrawerChanged(newStoreDrawer: boolean) {
    this.drawer = newStoreDrawer;
  }

  @Watch('drawer', { deep: true })
  onDrawerChanged(newValue: boolean) {
    if (!newValue) {
      this.$store.commit('closeDrawer');
    }
  }

  @Watch('$store.state.lang.lang', { deep: true })
  switchDir(newValue: string) {
    this.rightMenu = newValue === 'ar';
  }
}
