import Vue from 'vue';
import Component from 'vue-class-component';
import routes from '@/enums/routes.enum';

@Component
export default class ActiveApp extends Vue {
  getCurrentApp(appId: string) {
    const appItem = routes.find((item) => item.id === appId);
    const appPath = appItem?.path;
    this.$router.push(appPath || '/');
  }
}
